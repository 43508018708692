import * as React from "react";
import styled from "@emotion/styled";

import { AthleteList } from "./AthleteList"

interface Props {}
const tabletBreakpoint = "900px";
const mobileBreakpoint = "500px";
export const AthleteImages: React.FunctionComponent<Props> = ({}) => {

    return (
        <HeadshotWrapper>
            <AthleteBox>
                <AthleteList/>
            </AthleteBox>
        </HeadshotWrapper>
    );
};
const AthleteBox = styled.div`
    width: calc(40*44vw);
    height: 80vw;

    text-align: center;

    @media (min-width: ${mobileBreakpoint}) {
        width: 100%;
        height: 100%;
    }
`;
const HeadshotWrapper = styled.div`
    width: 100vw;
    height: 80vw;

    overflow-x: scroll;
    @media (min-width: ${mobileBreakpoint}){
        height: auto;
    }
`;
const BreakMobile = styled.br`
    @media (min-width: ${mobileBreakpoint}){
        display: none;
    }
`;