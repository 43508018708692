import React, { Component } from "react";
import styled from "@emotion/styled";


const tabletBreakpoint = "900px";
const mobileBreakpoint = "500px";

interface Props {}
export class AthleteList extends Component<Props>{
    state = {
     athletes: [{   
            firstName: "Rob",
            lastName: "Gronkowski",
            athleteID: "136",
            link: "https://fitplan.app.link/wTWUKnAkS5",
            image: "gronk-headshot.png",
        },{   
            firstName: "Melissa",
            lastName: "Alcantara",
            athleteID: "80",
            link: "https://fitplan.app.link/Hu7891vlS5",
            image: "mel-headshot.png",
        },{   
            firstName: "Alex",
            lastName: "Rodriguez",
            athleteID: "84",
            link: "https://fitplan.app.link/GfHUtNBmS5",
            image: "alex-headshot.png",
        },{   
            firstName: "Michelle",
            lastName: "Lewin",
            athleteID: "18",
            link: "https://fitplan.app.link/SX4JDNonS5",
            image: "michelle-headshot.png",
        },{   
            firstName: "Mike",
            lastName: "O'Hearn",
            athleteID: "50",
            link: "https://fitplan.app.link/6fnV19UnS5",
            image: "mike-headshot.png",
        },{   
            firstName: "Sami",
            lastName: "Clarke",
            athleteID: "82",
            link: "https://fitplan.app.link/jTEEsuSwS5",
            image: "sami-headshot.png",
        },{   
            firstName: "Corey",
            lastName: "Calliet",
            athleteID: "88",
            link: "https://fitplan.app.link/bJOeT2eCS5",
            image: "corey-headshot.png",
        },{   
            firstName: "Hattie",
            lastName: "Boydle",
            athleteID: "101",
            link: "https://fitplan.app.link/aREvhCKBS5",
            image: "hattie-headshot.png",
        },{   
            firstName: "Damien",
            lastName: "Patrick",
            athleteID: "90",
            link: "https://fitplan.app.link/2UYfeq8BS5",
            image: "damien-headshot.png",
        },{   
            firstName: "Jana",
            lastName: "Webb",
            athleteID: "147",
            link: "https://fitplan.app.link/qbSDDUCBS5",
            image: "jana-headshot.png",
        },{   
            firstName: "Jeff",
            lastName: "Seid",
            athleteID: "10",
            link: "https://fitplan.app.link/pWEMLADDS5",
            image: "jeff-headshot.png",
        },{   
            firstName: "Brittne",
            lastName: "Babe",
            athleteID: "53",
            link: "https://fitplan.app.link/pxUgDB7CS5",
            image: "brittne-headshot.png",
        },{   
            firstName: "Jimmy",
            lastName: "Lewin",
            athleteID: "66",
            link: "https://fitplan.app.link/YXWFjxGCS5",
            image: "jimmy-headshot.png",
        },{   
            firstName: "Lauren",
            lastName: "Findley",
            athleteID: "126",
            link: "https://fitplan.app.link/s1MaFXaBS5",
            image: "lauren-headshot.png",
        },{   
            firstName: "Cam",
            lastName: "Speck",
            athleteID: "72",
            link: "https://fitplan.app.link/wukbmsdzS5",
            image: "cam-headshot.png",
        },{   
            firstName: "Sommer",
            lastName: "Ray",
            athleteID: "137",
            link: "https://fitplan.app.link/EcmmHeqAS5",
            image: "sommer-headshot.png",
        },{   
            firstName: "Scott",
            lastName: "Mathison",
            athleteID: "70",
            link: "https://fitplan.app.link/kYkc2OvAS5",
            image: "scott-headshot.png",
        },{   
            firstName: "Kyla",
            lastName: "Ritchie",
            athleteID: "129",
            link: "https://fitplan.app.link/8Ycm7BnBS5",
            image: "kyla-headshot.png",
        },{   
            firstName: "Brendan",
            lastName: "Brazier",
            athleteID: "2",
            link: "https://fitplan.app.link/0OP1SNCGS5",
            image: "brendan-headshot.png",
        },{   
            firstName: "Katie",
            lastName: "Crewe",
            athleteID: "71",
            link: "https://fitplan.app.link/ncbS5qvBS5",
            image: "katie_c-headshot.png",
        },{   
            firstName: "Christian",
            lastName: "McCaffrey",
            athleteID: "92",
            link: "https://fitplan.app.link/rrj7vvtCS5",
            image: "christian-headshot.png",
        },{   
            firstName: "Jen",
            lastName: "Selter",
            athleteID: "48",
            link: "https://fitplan.app.link/sKM5FycDS5",
            image: "jen-headshot.png",
        },{   
            firstName: "Rob",
            lastName: "Riches",
            athleteID: "35",
            link: "https://fitplan.app.link/q37edFoDS5",
            image: "rob_r-headshot.png",
        },{   
            firstName: "Yanet",
            lastName: "Garcia",
            athleteID: "135",
            link: "https://fitplan.app.link/sQH34wIzS5",
            image: "yanet-headshot.png",
        },{   
            firstName: "Ryan",
            lastName: "Lochte",
            athleteID: "102",
            link: "https://fitplan.app.link/cKU0b3CAS5",
            image: "ryan-headshot.png",
        },{   
            firstName: "Danniebelle",
            lastName: " ",
            athleteID: "91",
            link: "https://fitplan.app.link/SQH8fQWBS5",
            image: "danniebelle-headshot.png",
        },{   
            firstName: "David",
            lastName: "Morin",
            athleteID: "74",
            link: "https://fitplan.app.link/ZtqRly3yS5",
            image: "david-headshot.png",
        },{   
            firstName: "Mona",
            lastName: "Muresan",
            athleteID: "81",
            link: "https://fitplan.app.link/iCEW1B2AS5",
            image: "mona-headshot.png",
        },{   
            firstName: "Lasse",
            lastName: "Matberg",
            athleteID: "76",
            link: "https://fitplan.app.link/5BZPdzKyS5",
            image: "lasse-headshot.png",
        },{   
            firstName: "Nochtli",
            lastName: "Peralta Alvarez",
            athleteID: "78",
            link: "https://fitplan.app.link/H34lcJMAS5",
            image: "nochtli-headshot.png",
        },{   
            firstName: "Mischa",
            lastName: "Janiec",
            athleteID: "69",
            link: "https://fitplan.app.link/qhNsAvACS5",
            image: "mischa-headshot.png",
        },{   
            firstName: "Natalie",
            lastName: "Jill",
            athleteID: "86",
            link: "https://fitplan.app.link/iZBXurUAS5",
            image: "natalie-headshot.png",
        },{   
            firstName: "Carmel",
            lastName: "Rodriguez",
            athleteID: "79",
            link: "https://fitplan.app.link/UXyVs5kCS5",
            image: "carmel-headshot.png",
        },{   
            firstName: "Dasha",
            lastName: "Gaivoronski",
            athleteID: "73",
            link: "https://fitplan.app.link/8l2BVXRBS5",
            image: "dasha-headshot.png",
        },{   
            firstName: "Amanda",
            lastName: "Cerny",
            athleteID: "77",
            link: "https://fitplan.app.link/Hb3R260GS5",
            image: "amanda-headshot.png",
        },{   
            firstName: "Carol",
            lastName: "Saraiva",
            athleteID: "37",
            link: "https://fitplan.app.link/7Tw5jAjDS5",
            image: "carol-headshot.png",
        },{   
            firstName: "Ariana",
            lastName: "James",
            athleteID: "32",
            link: "https://fitplan.app.link/qPK6xutDS5",
            image: "ariana-headshot.png",
        },{   
            firstName: "Katie",
            lastName: "Chung Hua",
            athleteID: "14",
            link: "https://fitplan.app.link/KHCaL5zDS5",
            image: "katie_ch-headshot.png",
        },{   
            firstName: "Marissa",
            lastName: "Rivero",
            athleteID: "54",
            link: "https://fitplan.app.link/PksKUdqyS5", 
            image: "marissa-headshot.png",
        }]
    }

    
    render(){
        const { athletes } = this.state;
        const athleteList = athletes.map((athlete, key) => {
            return(
                <a href={athlete.link} title={athlete.athleteID} key={key}>
                    <AthleteCard style={{backgroundImage: `url(https://images.fitplanapp.com/web/landing/social/athlete-headshots/${athlete.image}?tr=w-600)`}}>
                        <h1>
                            <b>{athlete.firstName}</b>
                            <BreakMobile/>
                            {" "}
                            {athlete.lastName}
                        </h1>
                    </AthleteCard>
                </a>
            )
        });
        return (
            <>
                {athleteList}
            </>
        );
    }
}

const BreakMobile = styled.br`
    @media (min-width: ${mobileBreakpoint}){
        display: none;
    }
`;
const AthleteCard = styled.div`
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.5);

    width: 40vw;
    height: 60vw;
    margin: 2vw;

    display: inline-block;
    position:relative;

    transition: .3s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    h1 {
        font-size:6vw;
        text-align:center;
        line-height: 100%;
        color: #fff;
        
        margin: 0;
        width:100%;
        
        display: block;
        position:absolute;
        bottom:2vw;

        b { 
            font-weight: 600
        }
    }
    @media (min-width: ${mobileBreakpoint}) {
        height: 16vw;
        width: 16vw;
        margin: 1vw;

        h1 {
            font-size:1.5vw;
            bottom:1.5vw;
        }
    }
`;