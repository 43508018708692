import React, { Component }   from "react";
import styled       from "@emotion/styled";

type Props= {}
const tabletBreakpoint = "900px";

export class IconContainer extends Component<Props>{
    render(){
        return (   
            <Container>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/weight.png?tr=w-300"/>
                    <br/><br/>
                    <section>
                        100<small>s</small> of home and<br/>gym workouts
                    </section>
                </div>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/run.png?tr=w-300"/>
                    <br/><br/>
                    <section>
                        Millions of workouts<br/>completed already
                    </section>
                </div>
                <div>
                    <img src="https://images.fitplanapp.com/web/landing/social/icons/tag.png?tr=w-300"/>
                    <br/><br/>
                    <section>
                        1 subscription unlocks<br/>40+ Elite Trainers
                    </section>
                </div>          
            </Container>
        );
    }
};

const Container = styled.div`
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    div{
        display: inline-block;
        width: 33vw;
        height: 22vw;
max-width: 300px;
        max-height: 200px;
        vertical-align: top;
        margin: 0 auto;
    }
    img {
        height: 20vw;
        width: 20vw;
        max-height: 120px;
        max-width: 120px;
        vertical-align: top;
    }
    section{
        width: 33vw;
        max-width: 300px;
        text-transform: uppercase;
        font-size: 2.5vw;
    }
    small {
        font-size: 80%;
    }
    @media (min-width: ${tabletBreakpoint}) {
        section {
            font-size: 21px;
        }
    }
`;