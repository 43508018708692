import React from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { AuthFormStrings } from "../../strings/AuthStrings";

import Modal from "../../modal/Modal";
import { SubscriptionModal } from "../../subscriptionNewVersion";
import {
    useStripeProduct,
    IStripeSubscription,
    adaptStripeSubscription,
    IStripeSubscriptionAdapter,
    getSubscriptionText,
} from "../../../hooks/useStripeProduct";
import { css } from "@emotion/core";

const breakpoint = "600px";

export const PurchaseContainer: React.FunctionComponent = props => {
    const data = useStaticQuery(graphql`
        query PurchaseContainer {
            subscribePage {
                title
                subtitle
            }
            homeSubscriptionBlock {
                primaryButton
                secondaryButton
            }
            startPage {
                success
                button
                exit
            }
        }
    `);
    const allSubscriptions = useStripeProduct(
        "prod_HE19jmtxqio54f",
        HARDCODED_STRIPE_SUBSCRIPTIONS,
        subs =>
            subs
                .filter(sub => sub.interval === "month")
                .map(sub => adaptStripeSubscription(sub))
    );
    const subscriptions = React.useMemo(() => getStripeSubs(allSubscriptions), [
        allSubscriptions,
    ]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedPlanId, setSelectedPlanId] = React.useState("");
    const selectedPlan = React.useMemo(() => {
        if (!selectedPlanId) return;
        return adaptStripeSubscription(
            subscriptions.find(sub => sub.id === selectedPlanId)
        );
    }, [subscriptions, selectedPlanId]);
    const monthly = React.useMemo(
        () =>
            adaptStripeSubscription(
                subscriptions.find(
                    sub => sub.interval === "month" && sub.intervalCount === 1
                )
            ),
        [subscriptions]
    );
    const quarterly = React.useMemo(
        () =>
            adaptStripeSubscription(
                subscriptions.find(
                    sub => sub.interval === "month" && sub.intervalCount === 3
                )
            ),
        [subscriptions]
    );
    const modalKey = React.useMemo(
        () => subscriptions.map(sub => sub.id).join("_"),
        [subscriptions]
    );

    const monthlyText = getSubscriptionText(monthly);
    const quarterlyText = getSubscriptionText(quarterly);
    return (
        <AuthFormStrings data={data}>
            <Modal open={modalOpen}>
                <div
                    css={css`
                        width: 100vw;
                        min-height: 100vh;
                        background: #111213;
                        z-index: 99999;
                        left: 0;
                        top: 0;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    {modalOpen && (
                        <SubscriptionModal
                            allowCoupon={false}
                            selectedPlan={selectedPlan}
                            availablePlans={subscriptions}
                            newCustomersOnly={"New Customers Only"}
                            selectedInvoice={null}
                            key={modalKey}
                        />
                    )}
                </div>
            </Modal>
            <Container>
                <div>
                    <div>most popular</div>
                    <br />
                    <br />
                    <div>Quarterly</div>
                    <h1>
                        3 Months for{" "}
                        <b>
                            {quarterlyText.currencySymbol}
                            {quarterlyText.dollars} {quarterlyText.amountSuffix}
                        </b>
                        .
                    </h1>
                    <InlineGrid>
                        <div>
                            <span>{quarterlyText.currencySymbol}</span>
                            <span>{quarterlyText.dollarsMonthly}</span>
                        </div>
                        <div>
                            <span>{quarterlyText.centsMonthly}</span>{" "}
                            <span>{quarterlyText.amountSuffix}</span>
                        </div>
                        <div>/{quarterlyText.intervalLong}</div>
                    </InlineGrid>
                    <br />
                    <button
                        onClick={() => {
                            setSelectedPlanId(quarterly.id);
                            setModalOpen(true);
                        }}
                    >
                        SUBSCRIBE NOW
                    </button>
                    <h3>
                        Billed quarterly at {quarterlyText.amountSuffix}{" "}
                        {quarterlyText.currencySymbol}
                        {quarterlyText.amount}
                    </h3>
                    <h3>Cancel for free within 48 hours</h3>
                </div>

                <div>
                    <h1>monthly</h1>
                    <InlineGrid>
                        <div>
                            <span>{monthlyText.currencySymbol}</span>
                            <span>{monthlyText.dollarsMonthly}</span>
                        </div>
                        <div>
                            <span>{monthlyText.centsMonthly}</span>{" "}
                            <span>{monthlyText.amountSuffix}</span>
                        </div>
                        <div>/{monthlyText.intervalLong}</div>
                    </InlineGrid>
                    <br />
                    <button
                        onClick={() => {
                            setSelectedPlanId(monthly.id);
                            setModalOpen(true);
                        }}
                    >
                        SUBSCRIBE NOW
                    </button>
                    <h3>
                        Billed monthly at {monthlyText.amountSuffix}{" "}
                        {monthlyText.currencySymbol}
                        {monthlyText.amount}
                    </h3>
                    <h3>To be charged when your 7 day Free Trial ends.</h3>
                </div>
            </Container>
        </AuthFormStrings>
    );
};

const Container = styled.div`
    text-align: center;
    max-width: ${breakpoint};
    margin: 0 auto;
    h1 {
        font-size: 21px;
        text-transform: uppercase;
        margin: 0 2% 1vw;
    }
    h1 > span {
        text-decoration: line-through;
        text-decoration-color: rgba(255, 0, 0, 0.6);
        text-decoration-style: solid 1px;
    }
    h2 {
        text-transform: capitalize;
    }
    h3 {
        font-size: 3vw;
    }
    button {
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 600;
        border: 0;
        border-radius: 5px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
        margin: 2vw auto 5vw;
        padding: 10px 25px;
        &:hover {
            box-shadow: none;
        }
        &:active {
            background-color: grey;
        }
    }
    > div {
        padding: 5%;
        margin: 0 auto;
        height: auto;
        border-radius: 15px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
        padding: 5%;
        height: auto;
        vertical-align: middle;
    }
    > div:first-of-type {
        color: #fff;
        background-image: linear-gradient(to left, #1144ff, #0076f9);

        width: 80vw;

        position: relative;
        z-index: 1;
        button {
            background-color: white;
            color: #1144ff;
        }
    }
    > div:first-of-type > div:first-of-type {
        font-size: 1.5em;
        font-weight: 800;
        text-transform: capitalize;
        display: inline-block;
    }
    > div:first-of-type > div:last-of-type {
        font-size: 30px;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 2px;

        color: #fff;
        border-radius: 0;
        border-top: 2px solid white;
        border-bottom: 2px solid white;

        padding: 5%;
        margin: 0 auto 20px;

        display: inline-block;
    }
    > div:last-of-type {
        color: #112212;
        background-color: rgba(23, 23, 23, 0.125);

        width: 60vw;
        margin-top: -5vw;

        button {
            background-color: #112212;
            color: #fff;
        }
        > div > br:first-of-type {
            height: 20%;
        }
        h1 {
            margin-top: 5vw;
        }
    }
    @media (min-width: ${breakpoint}) {
        h1 {
            margin-top: 0px !important;
        }
        h3 {
            font-size: 12px;
        }
        button {
            margin: 2vw auto 3vw;
        }
        > div {
            display: inline-block;
            max-width: ${breakpoint};
        }
        > div:first-of-type {
            width: 35%;
        }
        > div:last-of-type {
            width: 50%;
            margin-left: -5%;
            margin-top: 0;
            padding: 5% 1.25%;
        }
    }
`;

const InlineGrid = styled.section`
    vertical-align: top;

    display: inline-grid;
    grid-columns: 1fr 1fr;
    grid-rows: auto;
    grid-template:
        " dollar cents "
        " dollar permonth";

    > div:first-of-type {
        grid-area: dollar;
        font-size: 60px;
        display: inline-block;
        vertical-align: top;
        align-self: start;

        span:first-of-type {
            font-size: 33px;
            vertical-align: middle;
        }
    }
    > div:nth-of-type(2) {
        grid-area: cents;
        vertical-align: middle;
        align-self: end;

        span:first-of-type {
            font-size: 27px;
            align-self: start;
        }
        span:last-of-type {
            text-transform: uppercase;
        }
    }
    > div:last-of-type {
        grid-area: permonth;
    }
`;

const HARDCODED_STRIPE_SUBSCRIPTIONS: IStripeSubscription[] = [
    {
        lastResponse: null,
        rawJsonObject: null,
        active: true,
        aggregateUsage: null,
        amount: 1599,
        amountDecimal: 1599,
        billingScheme: "per_unit",
        created: 1591982447,
        currency: "usd",
        deleted: null,
        id: "price_1GtGYdFifHQBs0WqLJtyiTjU",
        interval: "month",
        intervalCount: 1,
        livemode: true,
        metadata: {},
        nickname: "Web Monthly US1599",
        object: "plan",
        product: "prod_HE19jmtxqio54f",
        tiers: null,
        tiersMode: null,
        transformUsage: null,
        trialPeriodDays: 7,
        usageType: "licensed",
        productObject: null,
    },
    {
        lastResponse: null,
        rawJsonObject: null,
        active: true,
        aggregateUsage: null,
        amount: 2500,
        amountDecimal: 2500,
        billingScheme: "per_unit",
        created: 1590105524,
        currency: "usd",
        deleted: null,
        id: "plan_HK2MvtzOWcwOr6",
        interval: "month",
        intervalCount: 3,
        livemode: true,
        metadata: {},
        nickname: "Web Quarterly US25",
        object: "plan",
        product: "prod_HE19jmtxqio54f",
        tiers: null,
        tiersMode: null,
        transformUsage: null,
        trialPeriodDays: null,
        usageType: "licensed",
        productObject: null,
    },
];

const getStripeSubs = (
    subs: IStripeSubscription[]
): IStripeSubscriptionAdapter[] => {
    let quarterly = null;
    const result = [];
    for (const sub of subs) {
        if (sub.interval === "month" && sub.intervalCount === 1) {
            result.push(sub);
        } else if (sub.interval === "month" && sub.intervalCount === 3) {
            quarterly = sub;
        }
    }
    result.push(quarterly);
    return result.map(sub => adaptStripeSubscription(sub));
};
