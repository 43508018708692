import * as React   from "react";
import styled       from "@emotion/styled";

import "../../subscribe.scss";

import { AthleteImages }   from "../../../components/landing/social/Athletes"
import { HomeWorkouts }    from "../../../components/landing/social/HomeWorkouts";

import { IconContainer }   from "../../../components/landing/social/IconPoints";
import { PurchaseContainer }   from "../../../components/landing/social/PurchaseContainer";
import FooterContainer         from "../../../components/landing/reusable/FooterContainer";
interface Props {

}
const tabletBreakpoint = "900px";
const mobileBreakpoint = "700px";

const SocialSubscribePage:  React.FunctionComponent<Props> = props => {

    return (
        <LocalWrapper>
            <MobileTitle>
                Meet your favorite workout app
            </MobileTitle>
            <HeroImage>
                <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/promo_web/hero-home_sale.png"/>
                <GifImg src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/gif/linn-slider_sm.gif"/>
            </HeroImage>
            <GradientContainer>
                <BreakDesktop/>
                <DesktopTitle>
                    Meet your favorite workout app
                </DesktopTitle>
                <br/>
                <Title>
                        ELITE PERSONAL TRAINING
                </Title>
                <br/>
                <PurchaseContainer/>
                <br/>
                <MissionStatement>
                    Fitplan is more than just workouts from top fitness trainers.{" "}
                    We’re a community inspiring one another{" "}
                    to unlock our greatest potential through health and fitness.{" "}
                    We rise up, we kick ass, and we encourage others to do the same.{" "}
                    Our movement starts with YOU. 
                </MissionStatement>
                <br/>
                <MissionStatement>
                        WE’RE BETTER AND STRONGER <b>TOGETHER</b>.
                </MissionStatement>
                
                <br/><br/>
                <IconContainer/>
                <br/><br/><br/><br/>    

   
                <Title>
                    TRY ONE OF OUR COMMUNITY
                    {" "}<br/>
                    FAVORITE HOME WORKOUT PLANS
                </Title>
                <br/>   
                <HomeWorkouts/>
                <br/>
                <Title>
                    CLICK TO FIND MORE
                    {" "}<BreakMobile/>
                    FROM OUR TRAINERS!
                </Title>
                <AthleteImages/>        
                <BreakDesktop/>
                <BreakDesktop/>
            </GradientContainer>
            <FooterContainer/>
        </LocalWrapper>
    );
};


const GifImg = styled.img`
    width: 29vw!important;
    max-width: 290px;
    margin-top: 5%!important;
`;

const HeroImage = styled.div`
    width: 100vw;
    overflow: hidden;
    height: calc(337.5/1200 * 100vw);

    position: absolute;

        img {
            height: auto;
            width: 100%;
            position: absolute;
            margin: auto;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
        @media (min-width: ${tabletBreakpoint}){
            img {
                height: calc(337.5/1200 * 100vw);
                width: 100%;
                height: auto;
            }
    }
`;
const GradientContainer = styled.div`
    box-shadow: inset 0 7px 9px -7px rgba(128,130,177,0.4);
    position: relative;
    margin-top: calc(337.5/1200 * 100vw);

    @media (min-width: ${tabletBreakpoint}){

    }
`;

const LocalWrapper = styled.div`
    font-family: Barlow;

    color:#1d222b;
    background: #fff;

    width: 100vw;
    height: 100vh;
    overflow: scroll;

    position: fixed;
`;

const BreakMobile = styled.br`
    @media (min-width: ${tabletBreakpoint}){
        display: none
    }
`;
const BreakDesktop = styled.br`
display: none;
    @media (min-width: ${tabletBreakpoint}){
        display: block;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-weight: 600;
    font-size: 5.5vw;
    width: 100%;
    text-transform:uppercase;
    @media (min-width: ${tabletBreakpoint}){
        font-weight: 800;
        font-size: 36px;
    }
`;
const MobileTitle = styled(Title)`
color:#fff;
    background-color: #202122;
    border: none;
    @media (min-width: ${tabletBreakpoint}){
        padding-top: 10px; 
    }

`;
const DesktopTitle = styled(Title)`
display: none;
@media (min-width: ${tabletBreakpoint}){
    display: none;
        }
`;

const MissionStatement = styled.p`
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 5%;
    @media (min-width: ${tabletBreakpoint}){
        padding: 0;
    }
`;

export default SocialSubscribePage;